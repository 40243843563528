#sidebar-wrapper {
    max-width: 240px;
    min-height: 100vh;
    height: 100%;
    position: sticky;
    top: 0;
    border-right: 1px solid #f5f5f5;

    background: linear-gradient(153deg, #343434, #487ba5, #33536c, #343536);
    background-size: 800% 800%;

    -webkit-animation: MenuGradient 31s ease infinite;
    -moz-animation: MenuGradient 31s ease infinite;
    animation: MenuGradient 31s ease infinite;

    a {
        text-decoration: none;
    }

    &, * {
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    }

    .menu-title {
        height: 55px;
        position: relative;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);

        .title {
            color: white;
            font-weight: bold;
            position: absolute;
            width: 100%;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .menu-item {
        color: white;
        background: transparent;
        border-radius: 0 !important;
        min-height: 55px;
        border: 0;
        padding-top: 16px;
        padding-bottom: 16px;

        .menu-icon, svg {
            margin: 0 10px;
        }

        &:hover {
            background: rgba(255, 255, 255, 0.4);
        }

        &.active {
            background: rgba(255, 255, 255, 0.1);
            border-right: 5px solid white;
        }
    }


    &.menu-toggled {
        svg {
            margin: 0 !important;
        }

        .title {
            font-size: 12px;
        }

        .menu-item {
            &.active {
                border-right: 1px solid white;
            }
        }
    }

    @media screen AND (max-width: 639px) {
        &.menu-toggled {
            max-width: 0 !important;
            overflow: hidden;
        }
    }

    @media screen AND (min-width: 640px) {
        &.menu-toggled {
            max-width: 60px;

            .menu-item {
                text-align: center;
                padding-left: 0;
                padding-right: 0;

                .menu-icon, svg {
                    margin: 0;
                }

                .menu-text {
                    display: block;
                    font-size: 8px;
                    color: darkgrey;
                }

                &:hover {
                    .menu-text {

                    }
                }
            }
        }
    }

}