.template-component {

    #save-template-btn {
        width: 100%;
        position: sticky;
        bottom: 0.5rem;
        z-index: 10;
    }

    .loader-wrapper {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.4);
        position: absolute;
        top: 0;
        left: 0;

        z-index: 999;

        .spinner-border {
            position: fixed;
            top: 50%;
            left: 50%;
        }
    }

    .tox-editor-header {
        top: 55px !important;
    }
}