#login {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw !important;
    max-width: 100vw;
    height: 100vh;
    overflow-x: hidden;

    .bg-image {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background: #1b4b72;


        img {
            height: 100%;
            width: 100%;
            object-fit: cover;

            /* Add the blur effect */
            filter: blur(4px);
            -webkit-filter: blur(4px);
            opacity: 0.8;
        }
    }

    .card {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        position: fixed;
        top: 40%;
        left: 50%;

        transform: translate(-50%, -50%);

        width: 90vw;
        max-width: 500px;

        .card-header {
            background: white;
            text-align: center;

            font-weight: bold;
            font-size: 25px;
        }
    }
}
