// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';
@import 'transitions';
@import 'tooltips';
@import "components/sidebar";

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'views/template';
@import 'views/login';

#app {
    min-height: 100vh;
    .h-full {
        min-height: 100vh;
    }
}


.hoverable {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: box-shadow 0.3s cubic-bezier(.25,.8,.25,1);

    &:hover {
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    }
}

nav.navbar {
    background: white;
    margin-bottom: 1rem;
    position: sticky;
    top: 0;
    z-index: 99;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

}



@-webkit-keyframes MenuGradient {
    0%{background-position:48% 0%}
    50%{background-position:53% 100%}
    100%{background-position:48% 0%}
}
@-moz-keyframes MenuGradient {
    0%{background-position:48% 0%}
    50%{background-position:53% 100%}
    100%{background-position:48% 0%}
}
@keyframes MenuGradient {
    0%{background-position:48% 0%}
    50%{background-position:53% 100%}
    100%{background-position:48% 0%}
}


@keyframes heartbeat {
    0% {
        transform: scale(.75);
    }
    20% {
        transform: scale(1);
    }
    40% {
        transform: scale(.75);
    }
    60% {
        transform: scale(1);
    }
    80% {
        transform: scale(.75);
    }
    100% {
        transform: scale(.75);
    }
}
